<template>
  <!-- <BaiSkeletonDriver v-if="!isLoaded" /> -->
   <!-- v-if="isLoaded" -->
  <section class="vechile-detail-section">
    <head_mobile />
    <div class="container">
      <div class="vechile-detail-container">
        <div class="vechile-detail_row">
          <div class="row gx-5 justify-content-center">
            <div class="col-lg-9">
              <div class="vechile-detail__block">
                <div class="row justify-content-center">
                  <div class="col-lg-11 col-md-7 form-left-column">
                    <Title :enTitle="$t('driver')" :arTitle="$t('driver')" :isShow="true" />

                    <div class="vechile-details-form">
                      <Driver />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent, onMounted, ref, computed } from "vue";
import CarThumbUp from "@/layouts/extras/CarThumbUp.vue";
import Title from "@/layouts/reusable/Title.vue";
import Models from '@/core/services/car/Models';
import TrimLevels from '@/core/services/car/TrimLevels';
import EstimatedValues from '@/core/services/car/EstimatedValues';
import Manufacturers from '@/core/services/car/Manufacturers';
import Driver from "@/components/new/Driver.vue";
import CarDriver from "@/core/services/car/Drivers";
import Stepper from "@/core/services/etc/Steppers";
import BaiSkeletonDriver from "@/layouts/skeleton/driver.vue";
import { store } from "@/store";
import head_mobile from '@/layouts/head_mobile.vue';

export default defineComponent({
  created() {
    CarDriver.setInitialDriverDetails();
    const routeValue = {
      vehicle: "done",
      driver: "active",
      compare: "",
      cover: "",
    };
    Stepper.setVehicleDetails(routeValue);
  },

  components: {
    CarThumbUp,
    Title,
    Driver,
    BaiSkeletonDriver,
    head_mobile
  },

  setup() {
    const isLoaded = ref(false);

    const current = computed(() => {
      return store.state.steppers.stepper;
    });

    onMounted(() => {
      setTimeout(() => {
        isLoaded.value = true;
      }, 500);

      const myStorage = window.localStorage.getItem("customer_detatils");
      if (myStorage) {
        const vehicle = JSON.parse(myStorage);
        // setVehicle.setVehicle(vehicle);
        if(vehicle.car_year) {
          Manufacturers.getManufacturers({year: vehicle.car_year});
        }
        if(vehicle.manufacturer_id) {
      console.log({year: vehicle.car_year, manufacturer_id: vehicle.manufacturer_id});
          Models.getModelsByManufacturerId({year: vehicle.car_year, manufacturer_id: vehicle.manufacturer_id});
        }
        if(vehicle.model_id) {
          TrimLevels.getTrimLevelsByYearAndModelId({
            model_id: vehicle.model_id,
            year: vehicle.car_year,
          });
        }
        if(vehicle.trim_level_id) {
          EstimatedValues.getEstimatedValuesByYearManufacturerIdModelIdTrimLevelId(
            {
              car_year: vehicle.car_year,
              manufacturer_id: vehicle.manufacturer_id,
              model_id: vehicle.model_id,
              trim_level_id: vehicle.trim_level_id,
            }
          );
        }
      }

    });

    return {
      current,
      isLoaded,
    };
  },
});
</script>

<style lang="scss" scoped>
@media screen and (min-width: 1200px) {
  .row-input .row {
    --bs-gutter-x: 2rem;
  }
}
</style>
