<template>
    <div class="col-lg-4 col-md-6">
        <div class="sidebar-offer d-none d-md-block">
            <div class="sidebar-offer_content">
                <div class="sidebar-offer_content_title">
                <h6 class="mb-0">{{ $t('side_title') }}</h6>
            </div>
                
            <div class="sidebar-offer_content_desc">
                <p>{{ $t('side_sub_title') }} </p>
            </div>
            </div>
            <div class="car-offer position-relative text-center">
                <div class="car-offer-car position-relative">
                    <img src="@/assets/images/car.png" class="img-fluid">
                    <div class="car-offer-car_hand position-absolute">
                        <img src="@/assets/images/hand.png" class="img-fluid">
                    </div>
                </div>
                <div class="car-offer-text text-white position-relative">
                    <h5 class="mb-0">{{ $t('side_footer') }}</h5>
                </div>
            </div>

            </div>
    </div>
</template>

<style scoped>
    .sidebar-offer {
        position: -webkit-sticky;
        position: sticky;
        top: 100px;
        align-self: flex-start;
    }
</style>